import BaseForm from 'forms/scripts/base_form'
import 'jquery-validation'

const CHAT_FORM_SELECTOR = '[data-role="chat_contact_form"]'

class ChatContactForm extends BaseForm {
  static init (liveChatInstance) {
    return new ChatContactForm(liveChatInstance)
  }
  constructor (liveChatInstance) {
    super(CHAT_FORM_SELECTOR)

    this.liveChatInstance = liveChatInstance
  }

  onSubmit (e) {
    e.preventDefault()
    this.liveChatInstance.onSubmit(e, this.formData())
  }
}

export default ChatContactForm
