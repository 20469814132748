/* global I18n */

const chatTemplate = `
  <div class="chat border hidden [chat_template_class]">
    <div class="d-flex flex-wrap">
      <div class="messages__container w-100">

      </div>
      <div class="messages_input__container d-flex w-100 border-top">
        <div class="messages_input__wrapper h-100">
          <textarea class="messages_input border-right bg-white h-100" placeholder="${I18n.t('type_your_message')}" style="outline: 0;"></textarea>
        </div>
        <button class="btn border-0 btn-light rounded-0 send-message h-100 d-flex align-items-center justify-content-center shadow-none ">
          <i class="send-message-icon fa fa-paper-plane"></i>
        </button>
      </div>
      <div class="chat-overflow hidden p-3" data-role="message-form">
      </div>
    </div>
  </div>
`

export default chatTemplate
