/* global I18n */

import { createConsumer } from '@rails/actioncable'
import chatTemplate from './chat_template'
import Track from 'tracking_events/scripts/track'
import 'jquery-validation'
import { emailRegexp } from 'utils/email'
import ChatContactForm from './chat_contact_form'

const SELECTORS = {
  submitChatEmail: '[data-role="chat_email_submit_btn"]',
  emailInput: '[data-role="chat_email_input"]',
  emailForm: '[data-role="chat_email_form"]',
  messageForm: '[data-role="message-form"]',
  automatedResponse: '[data-automated-chat-response="true"]'
}

const I18nMap = {
  en: 'en-US',
  de: 'de-DE',
  fr: 'fr-FR',
  it: 'it-IT',
  nl: 'nl-NL',
  pt: 'pt-PT',
  'zh-CN': 'zh-CN',
  cs: 'cs-CZ',
  da: 'da-DK',
  ru: 'ru-RU',
  sk: 'sk-SK'
}

const OPTIONS = {
  hour: 'numeric',
  minute: '2-digit'
}

class LiveChat {
  static async init () {
    if ($('.live-chat-icon').length === 0) return

    return new LiveChat()
  }

  constructor () {
    this.initializeStates()

    this.prepareChatContainer()

    if (window.location.search.match(/chat=true/)) {
      this.toggleChatWindow()
    }

    if (this.email) {
      this.onFirstChatOpen()
      this.loadChatMessages()
    }
  }

  initializeStates () {
    this.isOpened = false
    this.isLoaded = false
    this.formData = {}
    this.messages = this.getMessages()
  }

  bindEvents () {
    this.bindToggleChatWindow()
    this.bindCloseChat()
    this.bindSubmitButton()
    this.bindMarkMessagesAsRead()
    this.bindMessageInputKeyPress()
  }

  bindToggleChatWindow () {
    $('.live-chat-icon').click((e) => {
      e.preventDefault()
      this.toggleChatWindow()
    })
  }

  bindCloseChat () {
    $('.site-footer, .page-inner').on('click', () => {
      this.closeChat()
    })
  }

  bindSubmitButton () {
    this.$chat.find('.send-message').click((e) => this.submit())
  }

  bindMarkMessagesAsRead () {
    this.$messageInput.on('focus', () => this.markMessagesAsRead())
  }

  bindMessageInputKeyPress () {
    this.$messageInput.keypress(event => {
      if (event.which === 13 && !event.shiftKey) {
        this.submit()
      }
    })
  }

  bindChatElements () {
    this.$messagesContainer = this.$chat.find('.messages__container')
    this.$messageInput = this.$chat.find('.messages_input')
    this.$unreadMessagesBadge = $('.live-chat-icon .floating-btn--indicator')
    this.$unreadMessagesBadgeBackground = $('.live-chat-icon .floating-btn--background')
    this.email = JSON.parse(localStorage.getItem('contacts') || '{}').email
    this.subscribed = false
    this.bindEvents()
  }

  setChatContainer () {
    this.chatWithContactForm = $('[data-chat-contact-form]').data('chat-contact-form')

    this.$chat = $(chatTemplate.replace('[chat_template_class]', this.chatWithContactForm ? 'chat-contact-form' : 'chat-email-form'))
  }

  async prepareChatContainer () {
    this.setChatContainer()
    this.bindChatElements()
  }

  onFirstChatOpen () {
    $(document.body).append(this.$chat)

    this.prefillWithMessages()

    if (this.email) {
      this.joinGuestChat()
    }

    this.isLoaded = true
  }

  toggleChatWindow () {
    if (this.$chat.hasClass('chat--visible')) {
      this.closeChat()
    } else {
      this.openChat()
    }
  }

  closeChat () {
    this.$chat.removeClass('chat--visible')
    this.$chat.fadeOut(300)

    if (this.$unreadMessagesBadge.text()) {
      this.displayUnreadMessagesBadge(true)
    }
  }

  openChat () {
    if (!this.isOpened) {
      this.isOpened = true

      if (!this.isLoaded) {
        this.onFirstChatOpen()
      }

      new Track().send('event', {
        event_category: 'live_chat',
        event_action: 'open'
      })
    }

    this.$chat.fadeIn(150)
    this.$chat.addClass('chat--visible')

    this.displayUnreadMessagesBadge(false)
    this.$messageInput.focus()
    this.scrollToBottom()
  }

  getDesignatedForm () {
    if (this.chatWithContactForm) {
      return $.getJSON('/api/leads/new', { data_role: 'chat_contact_form', id: 'chat_contact_form' }, (json) => {
        $(SELECTORS['messageForm']).append(`
          <div class="text-white mb-3 small text-center">${I18n.t('lets_begin_by_filling_out')}</div>
          ${json.html}
        `)
      })
    } else {
      $(SELECTORS['messageForm']).append(`
        <form class="chat-email-form d-flex h-100 flex-column align-items-center justify-content-center">
          <div class="text-white mb-4">${I18n.t('provide_email')}</div>
          <div class="input-group overflow-email-input animated" data-role="chat_email_form">
            <input class="form-control" name="email" placeholder="${I18n.t('email')}" type="email" data-role="chat_email_input">
            <div class="input-group-append">
              <button class="btn btn-primary" data-role="chat_email_submit_btn" type="submit">
                ${I18n.t('submit')}
              </button>
            </div>
          </div>
        </form>`)
    }
  }

  async submit () {
    this.message = this.$messageInput.val().trim()

    if (!this.message) {
      this.$messageInput.focus()
      return
    }

    if (this.email) {
      this.sendMessage()
    } else {
      $('.chat-overflow').fadeIn(200)

      if (this.chatWithContactForm) {
        await this.getDesignatedForm()
        ChatContactForm.init(this)
      } else {
        this.getDesignatedForm()
        this.askForEmail()
      }
    }
  }

  onSubmit (e, formData) {
    this.formData = formData
    e.preventDefault()
    this.sendMessageThenSaveToLocalStorage()
  }

  sendMessage () {
    if (this.message) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: '/api/chat_messages',
          method: 'POST',
          dataType: 'json',
          data: {
            chat_message: {
              first_name: this.formData.first_name,
              last_name: this.formData.last_name,
              phone: this.formData.phone,
              location: this.formData.location,
              email: this.email,
              message: this.message,
              receive_newsletters: ('receive_newsletters' in this.formData)
            }
          }
        }).done((response) => {
          const message = { id: response.data.id, message: this.message, type: 'customer' }

          this.renderMessage(message, true)

          if (this.messages.length === 0 && window.gtag) {
            const isEnabled = $('[data-chat-analytics]').data('chat-analytics') === 'enabled'

            if (isEnabled) {
              window.gtag('event', 'submit', {
                'event_category': 'chat',
                'event_label': 'click'
              })
            }
          }

          this.addMessageToLocalStorage(message)
          this.$messageInput.val('')
          resolve()
        }).fail((error) => {
          console.error(error)
          reject(error)
        })
      })
    }
  }

  markMessagesAsRead () {
    if (this.email) {
      const sellerMessages = this.getMessages().filter(message => message.type === 'seller')
      const lastSellerMessage = sellerMessages[sellerMessages.length - 1]

      $.ajax({
        url: '/api/chat_messages/read',
        method: 'POST',
        dataType: 'json',
        data: {
          chat_message: {
            email: this.email,
            last_seller_message_id: lastSellerMessage && lastSellerMessage.id
          }
        }
      }).fail((error) => {
        console.error(error)
      })
    }

    this.$unreadMessagesBadge.text('')
    this.displayUnreadMessagesBadge(false)
  }

  renderMessage ({ id, type, message }, animate = false) {
    const $message = $(`
      <div class="${animate ? 'animate bounceIn ' : ''}chat_message chat_message--from-${type}" data-chat-id=${id}>
        <span class="text-pre-line notranslate">${message}</span>
      </div>
    `)

    this.$messagesContainer.append($message)

    setTimeout(() => {
      $message.removeClass('animate')
      $message.removeClass('bounceIn')
    }, 500)

    this.scrollToBottom()
  }

  askForEmail () {
    $('.chat-overflow').fadeIn(200)

    this.bindEmailFormEvents()
  }

  bindEmailFormEvents () {
    $(SELECTORS['submitChatEmail']).on('click', (e) => {
      e.preventDefault()
      this.sendMessageThenSaveToLocalStorage()
    })

    $(SELECTORS['emailInput']).on('keypress', (e) => {
      this.removeHeadShakeClass()
    })
  }

  sendMessageThenSaveToLocalStorage () {
    if (this.chatWithContactForm) {
      this.email = this.formData.email
    } else {
      this.email = $('.overflow-email-input input').val()
    }

    if (this.email.match(emailRegexp)) {
      $('.chat-overflow').fadeOut(100)

      const sendMessageResponse = this.sendMessage()

      if (sendMessageResponse) {
        sendMessageResponse.then(() => {
          this.saveContactToLocalStorage()

          if ($(SELECTORS['automatedResponse']).data('automated-chat-response')) {
            setTimeout(() => {
              this.renderMessage({ id: null, type: 'seller', message: I18n.t('we_will_respond_to_you') }, true)
            }, 1000)
          }
        })

        if (!this.subscribed) {
          this.joinGuestChat()
        }
      }
    } else {
      this.addHeadShakeClass()
    }
  }

  saveContactToLocalStorage () {
    const contacts = JSON.parse(localStorage.getItem('contacts') || '{}')

    localStorage.setItem('contacts', JSON.stringify({
      ...contacts,
      ...this.formData,
      email: this.email
    }))
  }

  addHeadShakeClass () {
    $(SELECTORS['emailForm']).addClass('headShake')
  }

  removeHeadShakeClass () {
    $(SELECTORS['emailForm']).removeClass('headShake')
  }

  joinGuestChat () {
    const cableURL = document.querySelector('meta[name="cable-url"]').content
    const consumer = createConsumer(`${cableURL}?email=${this.email}`)
    const channelName = { channel: 'GuestChatChannel' }
    const mixin = { received: $.proxy(this.onNewGuestChatMessage, this) }
    consumer.subscriptions.create(channelName, mixin)
    this.subscribed = true
  }

  onNewGuestChatMessage (message) {
    if (message.type === 'new_message') {
      this.loadChatMessages()
    } else {
      const { chat_message_id: chatMessageId } = message
      this.checkLastSentMessage(chatMessageId)
    }
  }

  checkLastSentMessage (messageId) {
    $.getJSON(`/api/chat_messages/${messageId}?email=${this.email}`, (json) => {
      const newMessage = json.data.attributes
      if (newMessage.read_at) {
        this.addReadTimestamp({ id: messageId, ...newMessage })
      }
    })
  }

  isBefore (date1, date2) {
    return date1 < date2
  }

  isBeforeDate (readAt) {
    const today = new Date()

    const readAtDay = readAt.getDate()
    const readAtMonth = readAt.getMonth() + 1
    const readAtYear = readAt.getFullYear()

    const todayDay = today.getDate()
    const todayMonth = today.getMonth() + 1
    const todayYear = today.getFullYear()

    const beforeDate = new Date(`${readAtYear}-${readAtMonth}-${readAtDay}`)
    const todayDate = new Date(`${todayYear}-${todayMonth}-${todayDay}`)

    return this.isBefore(beforeDate, todayDate)
  }

  addReadTimestamp (message) {
    let formattedDate
    const readAt = new Date(message.read_at)

    if (this.isBeforeDate(readAt)) {
      formattedDate = new Intl.DateTimeFormat(I18nMap[I18n.lang] || I18n.lang, {
        ...OPTIONS,
        year: 'numeric',
        month: 'short',
        day: '2-digit'
      }).format(readAt)
    } else {
      formattedDate = new Intl.DateTimeFormat(I18nMap[I18n.lang] || I18n.lang, OPTIONS).format(readAt)
    }

    const html = `
      <div class="chat_timestamp-badge small text-right text-secondary ${I18n.has('read_at') ? 'notranslate' : ''}">
        ${I18n.t('read_at', { timestamp: formattedDate })}
      </div>
    `
    $('.chat_timestamp-badge').hide()
    $(`[data-chat-id=${message.id}]`).after(html)

    this.scrollToBottom()
  }

  loadChatMessages () {
    $.getJSON(`/api/chat_messages?email=${this.email}`, (json) => {
      const data = json.data
      if (data.length && data.length.toString() !== this.$unreadMessagesBadge.text()) {
        this.displayUnreadMessagesBadge(true)
        this.$unreadMessagesBadge.text(data.length)
      }

      if (data.length === 0) {
        const lastChatMessage = this.messages[this.messages.length - 1]

        if (lastChatMessage && lastChatMessage.id) {
          this.checkLastSentMessage(lastChatMessage.id)
        }
      } else {
        data.forEach((message) => {
          if (!$(`[data-chat-id=${message.id}]`).length) {
            const msg = { id: message.id, type: 'seller', message: message.attributes.message }
            this.renderMessage(msg, true)
            this.addMessageToLocalStorage(msg)
          }
        })
      }
    })
  }

  prefillWithMessages () {
    if (this.messages[0]) {
      this.messages.forEach((message) => {
        this.renderMessage(message)
      })
    } else {
      setTimeout(() => {
        this.renderMessage({ id: null, type: 'seller', message: I18n.t('default_chat_message') }, true)
      }, 1000)
    }
  }

  addMessageToLocalStorage (message) {
    this.messages.push(message)

    localStorage.setItem('chat-messages', JSON.stringify(this.messages))
  }

  displayUnreadMessagesBadge (show) {
    if (show) {
      this.$unreadMessagesBadge.show()
      this.$unreadMessagesBadgeBackground.removeClass('invisible')
    } else {
      this.$unreadMessagesBadge.hide()
      this.$unreadMessagesBadgeBackground.addClass('invisible')
    }
  }

  scrollToBottom () {
    this.$messagesContainer.scrollTop(this.$messagesContainer.prop('scrollHeight'))
  }

  getMessages () {
    const messages = JSON.parse(localStorage.getItem('chat-messages') || '[]')

    return messages.filter(message => this.hasRequiredProps(message))
  }

  hasRequiredProps (message) {
    return [message.hasOwnProperty('id'), message.hasOwnProperty('message'), message.hasOwnProperty('type')].every(el => el)
  }
}

export default LiveChat
